<div class="recurring-date-container">
    <mbsc-segmented-group themeVariant="light" [(ngModel)]="repeatType" [ngModelOptions]="{standalone: true}">
        <mbsc-segmented value="daily">{{ 'features.addReservation.RECURRING.DAILY' | translate}}</mbsc-segmented>
        <mbsc-segmented value="weekly">{{ 'features.addReservation.RECURRING.WEEKLY' | translate}}</mbsc-segmented>
        <mbsc-segmented value="monthly">{{ 'features.addReservation.RECURRING.MONTHLY' | translate}}</mbsc-segmented>
    </mbsc-segmented-group>
        <div class=" custom-repeat-settings ml-3" *ngIf="showMaxOccurenceMessage" >
            <p class="custom-repeat-desc ng-star-inserted"><span class="text-yellow-500"><i class="fa-solid fa-triangle-exclamation"></i></span>  <span class="text-red-500"> {{ 'features.addReservation.RECURRING.MAX_OCCURENCES_LIMIT_MESSAGE' | translate :  { equipname: equipment.name, maxoccur: equipment.maxRecurringReservationOccurences} }}</span></p>
       </div>

    <div class="custom-repeat-settings ml-3">
        {{ 'features.addReservation.RECURRING.REPEAT_EVERY' | translate}}
        <mbsc-input themeVariant="light" class="custom-repeat-input" [(ngModel)]="repeatNr"
            (ngModelChange)="getCustomRule()" [ngModelOptions]="{standalone: true}" inputStyle="outline">
        </mbsc-input>
        <span *ngIf="repeatType === 'daily'">{{ 'features.addReservation.RECURRING.DAYS' | translate}}</span>
        <span *ngIf="repeatType === 'weekly'">{{ 'features.addReservation.RECURRING.WEEKS' | translate}}</span>
        <span *ngIf="repeatType === 'monthly'" class="custom-repeat-text custom-repeat-monthly">
            {{ 'features.addReservation.RECURRING.MONTHS_ON_DAY' | translate}}
            <mbsc-select themeVariant="light" class="custom-repeat-input custom-repeat-select-nr"
                [(ngModel)]="monthlyDay" (ngModelChange)="getCustomRule()" [ngModelOptions]="{standalone: true}"
                [data]="monthlyDays" inputStyle="outline">
            </mbsc-select>
        </span>

      
        <p *ngIf="repeatType === 'daily'" class="custom-repeat-desc">{{
            'features.addReservation.RECURRING.REPEAT_EVERY_DAYS_DESCRIPTION' | translate}}</p>
        <p *ngIf="repeatType === 'weekly'" class="custom-repeat-desc">{{
            'features.addReservation.RECURRING.REPEAT_EVERY_WEEKS_DESCRIPTION' | translate}}</p>
        <p *ngIf="repeatType === 'monthly'" class="custom-repeat-desc">{{
            'features.addReservation.RECURRING.REPEAT_EVERY_MONTHS_DESCRIPTION' | translate}}</p>

        <div *ngIf="repeatType === 'weekly'" class="custom-repeat-checkbox-cont">
            <mbsc-checkbox themeVariant="light" *ngFor="let day of weekDays"
                [label]="'features.addReservation.RECURRING.WEEKDAYS_SHORT.' + day.short | translate"
                [(ngModel)]="day.checked" (ngModelChange)="getCustomRule()" [ngModelOptions]="{standalone: true}">
            </mbsc-checkbox>
        </div>

        <div class="mt-3">{{ 'features.addReservation.RECURRING.STOP_CONDITION' | translate}}</div>
        <div class="custom-condition-cont">
            <mbsc-radio-group themeVariant="light" [(ngModel)]="condition" (ngModelChange)="getCustomRule()"
                [ngModelOptions]="{standalone: true}">
                <mbsc-radio themeVariant="light" [ngClass]="{'mbsc-checked': condition === 'until'}" value="until">
                    {{ 'features.addReservation.RECURRING.RUN_UNTIL_SPECIFIC_DATE' | translate}}
                    <mbsc-datepicker themeVariant="light" [controls]="['calendar']" display="anchored" [touchUi]="false"
                        dateFormat="YYYY-MM-DD" returnFormat="iso8601" [(ngModel)]="untilDate"
                        (ngModelChange)="getCustomRule()" [ngModelOptions]="{standalone: true}"
                        (click)="condition = 'until'" class="custom-repeat-input custom-specific-date"
                        inputStyle="outline">
                    </mbsc-datepicker>
                    <span class="mbsc-description">{{
                        'features.addReservation.RECURRING.RUN_UNTIL_SPECIFIC_DATE_DESCRIPTION' | translate}}</span>
                </mbsc-radio>
                <mbsc-radio themeVariant="light" [ngClass]="{'mbsc-checked': condition === 'count'}" value="count">
                    {{ 'features.addReservation.RECURRING.RUN_UNTIL_IT_REACHES' | translate}}
                    <mbsc-input themeVariant="light" class="custom-repeat-input" inputStyle="outline"
                        [(ngModel)]="occurrences" (ngModelChange)="getCustomRule()"
                        [ngModelOptions]="{standalone: true}" (click)="condition = 'count'"></mbsc-input>
                    {{ 'features.addReservation.RECURRING.OCCURENCES' | translate}}
                    <span class="mbsc-description">{{
                        'features.addReservation.RECURRING.RUN_UNTIL_IT_REACHES_DESCRIPTION' | translate}}</span>
                </mbsc-radio>
            </mbsc-radio-group>
        </div>

    </div>
</div>