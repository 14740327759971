import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  MbscPopupOptions,
  MbscPopup,
  MbscEventcalendarOptions,
  formatDate,
  MbscEventcalendarView,
  Notifications,
  MbscCalendarEvent,
  localeEn,
  localeFr
} from '@mobiscroll/angular';
import { MapLocationHistoryComponent } from '../../../equipment/components/map-location-history/map-location-history.component';
import { MatDialog } from '@angular/material/dialog';
import {
  Equipment,
  Reservation,
  ReservationMobiscroll,
  UserProfile,
} from 'src/app/data/index';
import * as moment from 'moment';
import { DialogService } from '@app/services/common/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from '@app/services/data/equipment.service';
import { ReservationService } from '@app/services/data/reservation.service';
import { UserRoleService } from '@app/services/common/user-role.service';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalStatus } from '@app/enums/approval-status-enum';
import { ROLES } from '@app/constants/roles.constants';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { takeUntil } from 'rxjs';
import 'moment/locale/fr';
import { MenuService } from '@app/services/common/menu.service';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss'],
})
export class ReservationDetailComponent
  extends VfmBaseComponent
  implements OnChanges, OnInit
{
  @Input() equipId!: number | null;
  @Input() type!: string;
  @Input() reservations!: Reservation[] | null;
  @Input() reservation!: Reservation | null;
  @Input() isFromMemberList: boolean = false;
  @Output() deletedReservationId: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() selectedReservationId: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() selectedWeek: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadReservations: EventEmitter<any> = new EventEmitter<any>();
  @Output() isTableViewSelected: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  reservationsMobiscroll!: ReservationMobiscroll[];
  isEventSelected = false;
  selectedReservation!: any;
  isAdmin = false;
  startDate = '';
  endDate = '';
  myEvents: MbscCalendarEvent[] = [];
  locale = this.profile && this.profile.language === 0 ? localeEn : localeFr;
  invalidDate: any = [];
  canEditReservation: boolean = false;
  timer: any;
  time = '';
  hoverEvent!: Reservation | any;
  anchor: HTMLElement | undefined;
  selectedUser!: UserProfile | null;
  equipments!: Equipment[];
  timelineResources: any[] = [];
  uniquesEquipmentFromReservation: any[] = [];
  currentDate: any = new Date();
  isTableView = true;

  @ViewChild('eventCalendar', { static: false })
  eventCalendar!: MbscCalendarEvent;

  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;

  timelineView: MbscEventcalendarView = {
    timeline: { type: 'week' },
  };

  eventSettingsTable: MbscEventcalendarOptions = {
    showEventTooltip: false,
    theme: 'ios',
    themeVariant: 'light',
    view: {
      schedule: {
        allDay: false,
        type: 'week',
        startTime: '00:00',
        endTime: '24:00',
      },
    },
    responsive: {
      xsmall: {
        view: {
          calendar: {
            type: 'week',
          },
          agenda: {
            type: 'week',
          },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 600,
        view: {
          schedule: {
            allDay: false,
            startTime: '00:00',
            endTime: '24:00',
          },
        },
      },
    },

    onEventHoverIn: (args, inst) => {
      const event: any = args.event;

      this.time =
        formatDate('hh:mm A', new Date(event.start)) +
        ' - ' +
        formatDate('hh:mm A', new Date(event.end));

      this.hoverEvent = event;

      clearTimeout(this.timer);
      this.timer = null;

      this.anchor = args.domEvent.target;

      this.tooltip.open();
    },
    onEventHoverOut: () => {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.tooltip.close();
        }, 200);
      }
    },
    onEventClick: (event) => {
      if (!this.isFromMemberList) {
        this.isEventSelected = true;

        this.selectedReservation = event.event;

        this.canEdit(
          this.selectedReservation.equipementCrewId,
          this.selectedReservation.userId
        );

        this.selectedReservationId.emit(this.selectedReservation.id);
      }
    },
    onCellClick: (event) => {},
    onPageChange: (event, inst) => {
      const weekDate = {
        startDate: moment(new Date(event.firstDay)).format('YYYY-MM-DD'),
        endDate: moment(new Date(event.lastDay)).format('YYYY-MM-DD'),
      };

      this.isEventSelected = false;
      this.selectedWeek.emit(weekDate);
    },
  };

  eventSettings: MbscEventcalendarOptions = {
    showEventTooltip: false,
    theme: 'ios',
    themeVariant: 'light',
    view: {
      timeline: {
        type: 'week',
      },
    },
    responsive: {
      xsmall: {
        view: {
          timeline: {
            type: 'week',
          },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 600,
        view: {
          timeline: {
            type: 'week',
          },
        },
      },
    },

    onEventHoverIn: (args, inst) => {
      const event: any = args.event;

      this.time =
        formatDate('hh:mm A', new Date(event.start)) +
        ' - ' +
        formatDate('hh:mm A', new Date(event.end));

      this.hoverEvent = event;

      clearTimeout(this.timer);
      this.timer = null;

      this.anchor = args.domEvent.target;

      this.tooltip.open();
    },
    onEventHoverOut: () => {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.tooltip.close();
        }, 200);
      }
    },
    onEventClick: (event) => {
      if (!this.isFromMemberList) {
        this.isEventSelected = true;

        this.selectedReservation = event.event;

        this.canEdit(
          this.selectedReservation.equipementCrewId,
          this.selectedReservation.userId
        );

        this.selectedReservationId.emit(this.selectedReservation.id);
      }
    },
    onCellClick: (event) => {},
    onPageChange: (event, inst) => {
      const weekDate = {
        startDate: moment(new Date(event.firstDay)).format('YYYY-MM-DD'),
        endDate: moment(new Date(event.lastDay)).format('YYYY-MM-DD'),
      };

      this.isEventSelected = false;
      this.selectedWeek.emit(weekDate);
    },
  };

  constructor(
    private notify: Notifications,
    private dialogService: DialogService,
    private translate: TranslateService,
    private equipmentService: EquipmentService,
    private reservationService: ReservationService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private roleUserService: UserRoleService,
    private dialog: MatDialog,
    private userProfileService: UserProfileService,
    private menuService: MenuService
  ) {
    super();

    this.menuService.getModifyMobileSize().subscribe((isMobileSize) => {
      if (isMobileSize) {
        this.isTableView = isMobileSize;
      } else {
        this.isTableView = localStorage.getItem('VFM_RESERVATION_TABLE_VIEW')
          ? localStorage.getItem('VFM_RESERVATION_TABLE_VIEW') === 'true'
          : true;
      }

      this.isTableViewSelected.emit(this.isTableView);
    });
  }

  ngOnInit(): void {
    const reservationId = this.route.snapshot.paramMap.get('id');

    if (this.router.url.includes('/admin')) {
      this.isAdmin = true;
    }

    this.loadEquipments();

    moment.locale(this.profile.language === 0 ? 'en' : 'fr');

    this.isTableView = localStorage.getItem('VFM_RESERVATION_TABLE_VIEW')
      ? localStorage.getItem('VFM_RESERVATION_TABLE_VIEW') === 'true'
      : true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equipId) {
      if (changes.equipId.currentValue > 1) {
        this.loadRestrictions();
      } else {
        this.invalidDate = [];
      }
    }

    if (changes.reservations && changes.reservations.currentValue) {
      this.convertReservationsMobiscroll();
      this.showAllreservations();
    }

    if (changes.reservation && changes.reservation.currentValue) {
      this.isEventSelected = true;

      if (this.selectedReservation) {
        this.selectedReservation.color =
          this.selectedReservation.approvalStatus === ApprovalStatus.pending
            ? '#454444'
            : '#008000';
        this.eventCalendar.updateEvent(this.selectedReservation);
      }

      this.selectedReservation = this.mobiscrollObjMapping(
        changes.reservation.currentValue,
        '#F1C400'
      );

      this.eventCalendar.updateEvent(this.selectedReservation);

      this.canEdit(
        changes.reservation.currentValue.equipementCrewId,
        changes.reservation.currentValue.userId
      );
    }
  }

  onTableViewChanged() {
    localStorage.setItem(
      'VFM_RESERVATION_TABLE_VIEW',
      this.isTableView.toString()
    );

    this.isTableViewSelected.emit(this.isTableView);
  }

  editAppointment(id: number, date:string) {
    if(moment(date).isBefore(moment())) {
      this.router.navigate(['reservation/modify', id]);
    }else {
      const dateofmodification = moment(date).format("YYYY-MM-DD");
      this.router.navigate(['reservation/modify', id], {queryParams: {dateofmodification:dateofmodification}});
    }

  }

  getFirstDayOfWeek(d: Date, prev: boolean): Date {
    const day = d.getDay();
    const diff = d.getDate() - day + (prev ? -7 : 7);
    return new Date(d.setDate(diff));
  }

  firstDayOfWeek() {
    let weekStart = moment(this.currentDate).clone().startOf('week');
    return weekStart.format('DD MMMM YYYY');
  }

  lastDayOfWeek() {
    let weekEnd = moment(this.currentDate).clone().endOf('week');
    return weekEnd.format('DD MMMM YYYY');
  }

  navigateToday(): void {
    const todayDate = new Date();

    this.currentDate = todayDate;
  }
  navigatePage(prev: boolean): void {
    const currentDate = this.currentDate;

    const prevNextSunday = this.getFirstDayOfWeek(currentDate, prev);

    this.currentDate = prevNextSunday;
  }

  getUniqueEquipmentFromReservation() {
    const map = new Map(
      this.reservations?.map((reservation) => [
        reservation.equipmentId,
        reservation,
      ])
    );

    const uniquesEquipment = [...map.values()];

    this.uniquesEquipmentFromReservation = uniquesEquipment.map(
      (reservation) => reservation.equipmentId
    );

    const filterEquipment = this.equipments?.filter((equipment) => {
      return this.uniquesEquipmentFromReservation.indexOf(equipment.id) !== -1;
    });

    const resourceEquipment: any = [];

    filterEquipment?.forEach((equipment) => {
      resourceEquipment.push({
        id: equipment.id,
        name: equipment.name,
        img: equipment.pictureUri,
      });
    });

    if (filterEquipment?.length === 0) {
      resourceEquipment.push({
        id: -1,
        name: 'No Reservation found!',
        img: '/assets/img/circle-exclamation.svg',
      });
    }

    this.timelineResources = [...resourceEquipment];
  }

  convertReservationsMobiscroll() {
    this.getUniqueEquipmentFromReservation();

    this.reservationsMobiscroll = this.reservations!.map(
      (reservation: Reservation) => {
        return this.mobiscrollObjMapping(reservation);
      }
    );
  }

  mobiscrollObjMapping(reservation: Reservation, color: string = '') {
    if (!color) {
      color =
        reservation.approvalStatus === ApprovalStatus.pending
          ? '#454444'
          : '#008000';
    }

    return {
      id: reservation.id!,
      equipmentId: reservation.equipmentId,
      approvalStatus: reservation.approvalStatus,
      start: moment(reservation.startDate!).format('YYYY-MM-DD[T]HH:mm'),
      end: moment(reservation.endDate!).format('YYYY-MM-DD[T]HH:mm'),
      recurring: reservation.rRule,
      recurringException: [
        ...reservation.exclusions,
        ...reservation.reccurenceExclusions.filter(Boolean),
      ],
      color: color,
      resource: reservation.equipmentId,
      equipementCrewId: reservation.equipementCrewId,
      equipmentName: reservation.equipmentName,
      userId: reservation.userId,
      userName: reservation.userName,
      description: reservation.description,
      deviceId: reservation.equipmentDeviceId,
      isAutoPIEnabled: reservation.equipmentIAutoPiEnabled,
      requestor:reservation.requestor,
      text: `<div style="text-align: center; margin-bottom: 10px;"></div><div class="my-block"><i class="fa-solid fa-truck mr-1"></i>${
        reservation.equipmentName
      }</div><div class="my-desc"><i class="fa-solid fa-user mr-1"></i>${
        reservation.userName
      }</div><div><i class="fa-solid fa-comment mr-1"></i>${
        reservation.description || 'N/A'
      }</div>`,
    };
  }

  showAllreservations() {
    this.myEvents = [...(this.reservationsMobiscroll || [])];
  }

  confirmRemoveReservation(equiId: number | null, id: number | null) {
    const equipmentId = equiId || this.selectedReservation.equipmentId;
    const reservationId = id || this.selectedReservation.id;
    this.dialogService
      .confirm(
        this.translate.instant(
          'features.reservation.CONFIRM_DELETE_RESERVATION.TITLE'
        ),
        this.translate.instant(
          'features.reservation.CONFIRM_DELETE_RESERVATION.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.equipmentService
            .deleteReservation(this.site.code, equipmentId, reservationId)
            .subscribe(() => {
              this.eventCalendar.removeEvent([reservationId]);
              this.isEventSelected = false;
              this.deletedReservationId.emit(reservationId);
              this.toastrService.success(
                this.translate.instant(
                  'features.reservation.DELETE_RESERVATION_SUCCESS'
                )
              );
            });
        }
      });
  }

  getUser(reservation: Reservation) {
    this.userProfileService
      .getUserProfile(reservation.userId!)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.selectedUser = result;

        this.extendReservation(reservation);
      });
  }

  extendReservation(reservation: Reservation) {
    let updateReservation: Reservation;
    let endDate = moment(reservation?.endDate).add(0.25, 'hour');

    updateReservation = {
      startDate: reservation?.startDate,
      endDate: endDate.format('YYYY-MM-DD[T]HH:mm:ss'),
      objectId: this.selectedUser!.objectId,
      description: reservation?.description,
      id: reservation?.id,
      rRule: reservation?.rRule,
      eventName: '',
    };

    if (reservation?.shiftTemplateId) {
      updateReservation.shiftTemplateId = this.selectedUser?.shiftTemplateId;
      updateReservation.shiftStartDate = this.selectedUser?.shiftStartDate;
      updateReservation.shiftEndDate = this.selectedUser?.shiftEndDate;
    }

    this.equipmentService
      .updateReservation(
        this.site.code,
        reservation.equipmentId!,
        updateReservation
      )
      .subscribe(() => {
        this.isEventSelected = false;
        this.toastrService.success(
          this.translate.instant(
            'features.reservation.EXTEND_RESERVATION_SUCCESS'
          )
        );

        this.reloadReservations.emit();
      });
  }

  confirmExtendReservation(event: ReservationMobiscroll) {
    this.dialogService
      .confirm(
        this.translate.instant(
          'features.reservation.CONFIRM_EXTEND_RESERVATION.TITLE'
        ),
        this.translate.instant(
          'features.reservation.CONFIRM_EXTEND_RESERVATION.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          const selectedReservation = this.reservations?.find(
            (reservation) => reservation.id === event.id
          );

          this.getUser(selectedReservation!);
        }
      });
  }

  showEdit() {
    if (this.isAdmin) {
      this.router.navigate([
        `/admin/reservation/modify/${this.selectedReservation.id}`,
      ]);
    } else {
      this.router.navigate([
        `reservation/modify/${this.selectedReservation.id}`,
      ]);
    }
  }

  showAdd() {
    if (this.isAdmin) {
      this.router.navigate(['admin/reservation/new']);
    } else {
      this.router.navigate(['reservation/new']);
    }
  }

  closeActionbar() {
    this.isEventSelected = false;
  }

  loadEquipments() {
    this.equipmentService
      .getEquipments(this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((equipments: any) => {
        this.equipments = equipments.data;
      });
  }

  loadRestrictions() {
    this.equipmentService
      .getAvailabilitiesByEquipmentId(this.site.code, this.equipId!, 0, 0)
      .subscribe((result: any) => {
        let invalidRestrictions = result.data.map(
          (restriction: Reservation) => {
            return {
              start: moment(
                restriction.startDate,
                'YYYY-MM-DD[T]HH:mm:ss'
              ).format('HH:mm'),
              end: moment(restriction.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format(
                'HH:mm'
              ),
              title: this.getTypeRestriction(restriction.availabilityType!),
              recurring:
                restriction.rRule + ';DTSTART=' + restriction.startDate,
            };
          }
        );

        this.invalidDate = [...invalidRestrictions];
      });
  }

  getTypeRestriction(availabilityType: number) {
    let type = '';

    switch (availabilityType) {
      case 1:
        type = 'All Restricted';
        break;

      case 2:
        type = 'Crew Restricted';
        break;

      case 3:
        type = 'Soft Restricted';
        break;
    }

    return type;
  }

  getTimeOffset() {
    return `${this.site.timeOffset}`;
  }

  canCancelInstanceRecurrenceReservationHover(
    event: ReservationMobiscroll
  ): boolean {
    let canStop = false;

    if (
      event.approvalStatus !== 0 &&
      event.recurring &&
      this.canEdit(event.equipementCrewId!, this.profile.id)
    ) {
      canStop = true;
    }

    return canStop;
  }

  canCancelSeriesRecurrenceReservationHover(
    event: ReservationMobiscroll
  ): boolean {
    let canStop = false;

    if (
      event.approvalStatus !== 0 &&
      event.recurring &&
      this.canEdit(event.equipementCrewId!, this.profile.id)
    ) {
      canStop = true;
    }

    return canStop;
  }

  canStopCurrentReservationHover(event: ReservationMobiscroll): boolean {
    let canStop = false;
    const currentTime = moment();

    if (
      event.approvalStatus !== 0 &&
      !event.recurring &&
      moment(event.start) < currentTime &&
      moment(event.end) > currentTime &&
      this.canEdit(event.equipementCrewId!, this.profile.id)
    ) {
      canStop = true;
    }

    return canStop;
  }

  canExtendReservationHover(event: ReservationMobiscroll): boolean {
    let canStop = false;
    const currentTime = moment();

    if (
      event.approvalStatus !== 0 &&
      !event.recurring &&
      moment(event.start) < currentTime &&
      moment(event.end) > currentTime &&
      this.canEdit(event.equipementCrewId!, this.profile.id)
    ) {
      canStop = true;
    }

    return canStop;
  }

  canStopCurrentReservation(): boolean {
    let canStop = false;
    const currentTime = moment();

    if (
      this.selectedReservation.approvalStatus !== 0 &&
      !this.selectedReservation.recurring &&
      moment(this.selectedReservation.start) < currentTime &&
      moment(this.selectedReservation.end) > currentTime &&
      this.canEdit(this.selectedReservation.equipementCrewId!, this.profile.id)
    ) {
      canStop = true;
    }

    return canStop;
  }

  canEdit(id: number, userId: number): boolean {
    const localStorageSite = localStorage.getItem('VFM_SITE');

    if (localStorageSite) {
      const site = JSON.parse(localStorageSite);
      const roles = this.roleUserService.getRoles() || [];
      const siteRole = ROLES.SITE_MANAGER.replace('{siteId}', site.id);
      const requiredRoles = [
        siteRole,
        ROLES.ADMIN,
        `Crew.${id}.Manager.ReadWrite.All`,
      ];

      if (requiredRoles.some((r) => roles.includes(r))) {
        this.canEditReservation = true;
      } else {
        this.canEditReservation = false;
      }

      if (userId === this.profile.id) {
        this.canEditReservation = true;
      }
    }

    return this.canEditReservation;
  }

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    width: 350,
  };

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
    }, 200);
  }

  getCustomRule(rRule: string) {
    if (rRule) {
      // convert rRule string to object
      const arrOfObjRules = rRule
        .split(';')
        .map((x) => x.split('=').map((y) => y.trim()))
        .reduce((a: any, x: any) => {
          a[x[0]] = x[1];
          return a;
        }, {});

      return arrOfObjRules;
    }
  }

  confirmStopCurrentReservation(equiId: number, reservationId: number) {
    this.dialogService
      .confirm(
        this.translate.instant(
          'features.reservation.CONFIRM_STOP_CURRENT_RESERVATION.TITLE'
        ),
        this.translate.instant(
          'features.reservation.CONFIRM_STOP_CURRENT_RESERVATION.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.equipmentService
            .stopCurrentReservation(this.site.code, equiId, reservationId)
            .subscribe(() => {
              this.reloadReservations.emit();

              this.toastrService.success(
                this.translate.instant(
                  'features.reservation.STOP_CURRENT_RESERVATION_SUCCESS'
                )
              );
            });
        }
      });
  }

  confirmStopRecurringReservationSeries(
    equiId: number,
    reservationId: number,
    date: string
  ) {
    this.dialogService
    .confirm(
      this.translate.instant(
        'features.reservation.CONFIRM_CANCEL_SERIES_RECURRING_RESERVATION.TITLE'
      ),
      this.translate.instant(
        'features.reservation.CONFIRM_CANCEL_SERIES_RECURRING_RESERVATION.DESCRIPTION'
      )
    )
    .subscribe((result: any) => {
      if (result) {
        this.equipmentService
          .recurrenceReservationSeriesDeletion(
            this.site.code,
            equiId,
            reservationId,
            moment(date).format('YYYY-MM-DD')
          )
          .subscribe(() => {
            this.reloadReservations.emit();

            this.toastrService.success(
              this.translate.instant(
                'features.reservation.STOP_CURRENT_RESERVATION_SUCCESS'
              )
            );
          });
      }
    });
  }

  confirmCancelRecurringReservationInstance(
    equiId: number,
    reservationId: number,
    date: string
  ) {
    this.dialogService
      .confirm(
        this.translate.instant(
          'features.reservation.CONFIRM_CANCEL_INSTANCE_RECURRING_RESERVATION.TITLE'
        ),
        this.translate.instant(
          'features.reservation.CONFIRM_CANCEL_INSTANCE_RECURRING_RESERVATION.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.equipmentService
            .recurrenceReservationInstanceExclusion(
              this.site.code,
              equiId,
              reservationId,
              moment(date).format('YYYY-MM-DD')
            )
            .subscribe(() => {
              this.reloadReservations.emit();

              this.toastrService.success(
                this.translate.instant(
                  'features.reservation.STOP_CURRENT_RESERVATION_SUCCESS'
                )
              );
            });
        }
      });
  }

  openDialog(deviceId: string) {
    const dialogRef = this.dialog.open(MapLocationHistoryComponent, {
      width: '800px',
      data: { deviceId },
    });
  }
}
