import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  formatDate,
  MbscCalendarEvent,
  MbscDatepickerOptions,
  MbscEventcalendarOptions,
  MbscPopup,
  MbscPopupOptions,
  setOptions,
  updateRecurringEvent,
  localeEn,
  localeFr,
} from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, takeUntil } from 'rxjs';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import * as moment from 'moment';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { DAYS } from '@app/constants/days.constants';
import { MONTHS } from '@app/constants/months.constants';
import { MONTH_DAYS } from '@app/constants/month_days.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { Equipment } from 'src/app/data';

@Component({
  selector: 'app-recurring-date',
  templateUrl: './recurring-date.component.html',
  styleUrls: ['./recurring-date.component.scss'],
})
export class RecurringDateComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  @Output() recurringRule: EventEmitter<any> = new EventEmitter<any>();
  @Input() form!: FormGroup;
  @Input() equipment!:Equipment;

  days = JSON.parse(JSON.stringify(DAYS));
  selectedRepeat = 'norepeat';
  recurringEditMode = 'current';
  repeatType: any = 'daily';
  repeatNr = 1;
  monthlyDays = MONTH_DAYS;
  monthlyDay = 1;
  selectedMonth = 1;
  weekDays = [...this.days];
  months = MONTHS;
  untilDate: any;
  occurrences = 1;
  condition = 'until';
  newEvent: MbscCalendarEvent = {};
  originalRecurringEvent: any;
  eventOccurrence: MbscCalendarEvent = {};
  editFromPopup = false;
  recurringDelete = false;
  recurringActionText = 'Edit';
  popup!: MbscPopup;
  popupEventTitle: string | undefined;
  popupEventDescription = '';
  popupEventAllDay = true;
  popupEventDates: any;
  switchLabel: any = 'All-day';
  showMaxOccurenceMessage = false;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.showMaxOccurenceMessage =  this.ShowMaxOccurrenceMessage();
    const reservationId = this.route.snapshot.paramMap.get('id');

    if (!reservationId) {
      // Set default stop condition to 3 month form now
      this.untilDate = new Date();
      this.untilDate.setMonth(this.untilDate.getMonth() + 3);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && changes.form.currentValue) {
      const formValues = this.form.getRawValue();
      const recurringRuleStr = formValues.rRule;

      this.setCustomRule(recurringRuleStr);
    }

    if(changes.equipment && changes.equipment.currentValue){
      this.showMaxOccurenceMessage =  this.ShowMaxOccurrenceMessage();
    }
  }

  public ShowMaxOccurrenceMessage() {
    return this.equipment && this.equipment.restrictMaxRecurringReservationOccurences
  }


  public setCustomRule(rRule: string) {
    if (rRule) {
      // convert rRule string to object
      const arrOfObjRules = rRule
        .split(';')
        .map((x) => x.split('=').map((y) => y.trim()))
        .reduce((a: any, x: any) => {
          a[x[0]] = x[1];
          return a;
        }, {});

      this.repeatType = arrOfObjRules.FREQ;
      this.repeatNr = arrOfObjRules.INTERVAL;
      this.condition = arrOfObjRules.until ? 'until' : 'count';

      if (this.repeatType === 'weekly') {
        const daysArr = arrOfObjRules.BYDAY.split(',');
        let days: any = [];

        daysArr.forEach((dayToAdd: string) => {
          days = this.days.map((day: any) => {
            if (day.short.toLowerCase() === dayToAdd.toLocaleLowerCase()) {
              day.checked = true;
            }
          });
        });

        this.days = days;
      }

      if (arrOfObjRules.UNTIL) {
        this.condition = 'until';
        this.untilDate = arrOfObjRules.UNTIL;
      } else {
        this.condition = 'count';
        this.occurrences = arrOfObjRules.COUNT;
      }

      console.log('arrOfObjRules', arrOfObjRules);
    }
  }

  public getCustomRule() {
    const formValues = this.form.getRawValue();
    let recurringRule: any;
    let recurringRuleStr: string = '';
    const d = this.editFromPopup
      ? this.popupEventDates[0]
      : new Date(formValues.startDate);
    const weekday = d.getDay();
    const monthday = d.getDate();
    const weekNr = this.getWeekDayNum(d);

    recurringRule = {
      repeat: this.repeatType,
      interval: this.repeatNr,
    };

    recurringRuleStr += `FREQ=${this.repeatType};INTERVAL=${this.repeatNr}`;

    switch (this.repeatType) {
      case 'weekly':
        recurringRule.weekDays = this.weekDays
          .filter((i) => i.checked)
          .map((i: any) => i.short)
          .join(',');

        recurringRuleStr += `;BYDAY=${recurringRule.weekDays}`;
        break;
      case 'monthly':
        recurringRule.day = this.monthlyDay;
        recurringRuleStr += `;BYMONTHDAY=${recurringRule.day}`;
        break;
    }

    switch (this.condition) {
      case 'until':
        recurringRule.until = this.untilDate;
        recurringRuleStr += `;UNTIL=${recurringRule.until}`;
        break;
      case 'count':
        recurringRule.count = this.occurrences;
        recurringRuleStr += `;COUNT=${recurringRule.count}`;
        break;
    }

    console.log('recurringRule', recurringRule);
    console.log('recurringRuleStr', recurringRuleStr);

    this.recurringRule.emit(recurringRuleStr);
  }

  getWeekDayNum(date: Date): number {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    let count = 0;

    for (
      let d = firstDayOfMonth;
      d < lastDayOfMonth;
      d.setDate(d.getDate() + 7)
    ) {
      if (date >= d) {
        count++;
      } else {
        break;
      }
    }

    return Math.max(1, count);
  }
}
